<template>
    <div>
        <div class="container pb-5">
            <SectionTitle title="Notas de prensa" /><br />
            <div class="row">
                <div v-for="(nota, index) in notas" :key="index" class="col-sm-4">
                    <div class="card mb-4">
                        <div class="card-body">
                            <img
                                class="mb-4 img-fluid text-center"
                                style="height: 300px; overflow: hidden; width: 100%"
                                :src="require('@/assets/comunicacion/notasPrensa/' + nota.thumbnail)"
                                alt="Notica"
                            />
                            <button
                                class="btn btn-success btn-block"
                                data-toggle="modal"
                                :data-target="`#nota-` + index"
                            >
                                Ver más
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div
                v-for="(nota, index) in notas"
                :key="index"
                class="modal fade bd-example-modal-xl"
                :id="'nota-' + index"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-center">
                            <img
                                v-for="(imagen, index) of nota.images"
                                :key="index"
                                class="mb-4 img-fluid"
                                :src="require('@/assets/comunicacion/notasPrensa/' + imagen)"
                                alt="Notica"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            notas: [
                { images: ["nota-23.jpg", "nota-23B.jpg"], thumbnail: "nota-23.jpg" },
                { images: ["nota-17.jpg"], thumbnail: "nota-17.jpg" },
                { images: ["nota-20.jpg"], thumbnail: "nota-20.jpg" },
                { images: ["nota-22.jpg"], thumbnail: "nota-22.jpg" },
                { images: ["nota-19.jpg"], thumbnail: "nota-19-thumb.jpg" },
                { images: ["nota-21.jpg"], thumbnail: "nota-21.jpg" },
                { images: ["nota-18.jpg"], thumbnail: "nota-18.jpg" },
                { images: ["nota-1.jpg"], thumbnail: "nota-1.jpg" },
                { images: ["nota-2.jpg"], thumbnail: "nota-2.jpg" },
                { images: ["nota-3.jpg"], thumbnail: "nota-3.jpg" },
                { images: ["nota-4.jpg"], thumbnail: "nota-4.jpg" },
                { images: ["nota-5.jpg"], thumbnail: "nota-5.jpg" },
                { images: ["nota-6.jpg"], thumbnail: "nota-6.jpg" },
                { images: ["nota-7.jpg"], thumbnail: "nota-7.jpg" },
                { images: ["nota-8.jpg"], thumbnail: "nota-8.jpg" },
                { images: ["nota-9.jpg"], thumbnail: "nota-9.jpg" },
                { images: ["nota-10.jpg"], thumbnail: "nota-10.jpg" },
                { images: ["nota-11.jpg"], thumbnail: "nota-11.jpg" },
                { images: ["nota-12.jpg"], thumbnail: "nota-12.jpg" },
                { images: ["nota-13.jpg"], thumbnail: "nota-13.jpg" },
                { images: ["nota-14.jpg"], thumbnail: "nota-14.jpg" },
                { images: ["nota-15.jpg"], thumbnail: "nota-15.jpg" },
                { images: ["nota-16.jpg"], thumbnail: "nota-16.jpg" },
            ],
        };
    },
};
</script>
